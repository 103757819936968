@keyframes vibrate {
    0%, 100% { transform: translateX(0); }
    20% { transform: translateX(-2px); }
    40% { transform: translateX(2px); }
    60% { transform: translateX(-1px); }
    80% { transform: translateX(1px); }
  }
  
  .vibrate {
    animation: vibrate 0.3s linear infinite;
  }
  
  .no-vibrate:hover .vibrate {
    animation: none;
  }
  