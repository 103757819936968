
/* engine section - text left and figure right ________________________________________________________________________ */
@tailwind base;
@tailwind components;
@tailwind utilities;


/* third section ________________________________________________________________________ */

.thirdSection_legacy {
  background: linear-gradient(to top left, #0021695a, #ffffff 100%);
}

.nextSection_legacy {
  background: linear-gradient(to top left, #ffffff , #002169 100%);
}

body {
  background: linear-gradient(to bottom right, #ffffff , #0021695a 100%);
  background-repeat: no-repeat;
}



/* für den sectorlens Titel: ____________________________________________________________________________________ */

@keyframes curveReveal {
    0% {
      transform: translateY(100%) translateX(10px);
      opacity: 0;
    }
    100% {
      transform: translateY(0) translateX(0);
      opacity: 1;
    }
  }
  
  .letter {
    display: inline-block; 
    opacity: 0; 
    animation: curveReveal 0.8s ease forwards;
  }
  
  .letter:nth-child(1) { animation-delay: 0.1s; }
  .letter:nth-child(2) { animation-delay: 0.2s; }
  .letter:nth-child(3) { animation-delay: 0.3s; }
  .letter:nth-child(4) { animation-delay: 0.4s; }
  .letter:nth-child(5) { animation-delay: 0.5s; }
  .letter:nth-child(6) { animation-delay: 0.6s; }
  .letter:nth-child(7) { animation-delay: 0.7s; }
  .letter:nth-child(8) { animation-delay: 0.8s; }
  .letter:nth-child(9) { animation-delay: 0.9s; }
  

  /* für den sectorlens sub-Titel Text: ____________________________________________________________________________________ */
  @keyframes slideInFromRight {
    0% {
      transform: translateX(30%); /* Start from slightly off-screen to the right */
      opacity: 0;
    }
    100% {
      transform: translateX(0); /* End at the final position */
      opacity: 1;
    }
  }
  
  .slide-in {
    opacity: 0; /* Ensure it starts invisible */
    animation: slideInFromRight 1.5s cubic-bezier(0.22, 0.61, 0.36, 1) forwards; /* Use a smooth timing function for the animation */
    animation-delay: 1.3s; /* Delay as needed */
  }



/* bewegung des texts links in der Grafik: ____________________________________________________________________________________ */
.text_figure_left {
    position: absolute;
    top: -20px;
    right: 50%;
    margin-right: 220px;
    text-align: right;

    text-transform: uppercase;
    background-image: linear-gradient(to right, #ffffff 90%, #3e2153 100%);
    background-size: auto auto;
    background-clip: border-box;
    background-size: 200% auto; 
    color: #fff;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: textclip 3s linear infinite;
    animation-delay: 1.5s;
    display: inline-block;
    font-size: 1rem; 
}

.text_figure_right {
    position: absolute;
    left: 50%;
    margin-left: 220px;
    text-align: left;
    top: -20px; 

    text-transform: uppercase;
    /* background-image: linear-gradient(to right, #ffffff 45%, #8c4cb9 50%, #bf8fe2 55%, #ffffff 60%);
    background-size: 200% auto;
    color: #fff;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; */
    /* animation: textclip 3.08s linear infinite; */
    display: inline-block;
    font-size: 1rem; 
    /* animation-delay: 7.5s; */
}

@keyframes textclip {
    from {
        background-position: 100% center;
    }
    to {
        background-position: -100% center;
    }
}

